import React, { useState, useEffect } from "react";
import WeightEstimatorFormFields from "./WeightEstimatorFormFields";

const materialFactors = {
  Default: 1.124,
  "ABS - Extrusion Grade": 0.134,
  "ABS - High Impact": 0.131,
  "Acetal - 20% Glass": 0.198,
  "Acetal - Copolymer": 0.18,
  "Acetal - Homopolymer": 0.18,
  "Acrylic - Cast": 0.152,
  "Alloy - 904L": 1.018,
  "Alloy - G": 1.046,
  "Alloy - HA-330": 1.021,
  "Aluminum - 1100": 0.3462,
  "Aluminum - 2011": 0.3462,
  "Aluminum - 2014": 0.3604,
  "Aluminum - 2017": 0.3568,
  "Aluminum - 2024": 0.3568,
  "Aluminum - 2117": 0.35,
  "Aluminum - 2219": 0.364,
  "Aluminum - 3003": 0.3498,
  "Aluminum - 3004": 0.346,
  "Aluminum - 4043": 0.343,
  "Aluminum - 5005": 0.3462,
  "Aluminum - 5052": 0.3427,
  "Aluminum - 5056": 0.3356,
  "Aluminum - 5083": 0.3392,
  "Aluminum - 5086": 0.3392,
  "Aluminum - 5456": 0.339,
  "Aluminum - 5657": 0.346,
  "Aluminum - 6061": 0.3462,
  "Aluminum - 6068": 0.343,
  "Aluminum - 6262": 0.346,
  "Aluminum - 7075": 0.3568,
  "Aluminum - 7178": 0.3604,
  "Aluminum - Tool & Jig Plate": 0.357,
  Azdel: 0.152,
  Beryllium: 0.236,
  "Brass - Naval": 1.074,
  "Brass - Red 115 (CDA 836)": 1.124,
  "Brass - Red 131 (CDA 833)": 1.124,
  "Brass - Red 131 (CDA 838)": 1.102,
  "Brass - Semi-Red 123 (CDA 844)": 1.11,
  "Brass - Semi-Red 130 (CDA 848)": 1.095,
  "Brass - Yellow 360": 1.084,
  "Brass - Yellow 400 (CDA 852)": 1.078,
  "Brass - Yellow 403 (CDA 854)": 1.078,
  "Brass - Yellow 405-2 (CDA 857)": 1.074,
  "Bronze - Aluminum 415A (CDA 952)": 0.975,
  "Bronze - Aluminum 415B (CDA 953)": 0.961,
  "Bronze - Aluminum 415C (CDA 954)": 0.951,
  "Bronze - Aluminum 415D (CDA 955 & 958)": 0.968,
  "Bronze - High Leaded Tin 305 (CDA 937)": 1.131,
  "Bronze - High Leaded Tin 310 (CDA 934)": 1.131,
  "Bronze - High Leaded Tin 315 (CDA 932)": 1.138,
  "Bronze - High Leaded Tin 319 (CDA 938)": 1.18,
  "Bronze - High Leaded Tin 322 (CDA 943)": 1.187,
  "Bronze - High Leaded Tin 326 (CDA 935)": 1.131,
  "Bronze - Leaded Tin 206 (CDA 927)": 1.12,
  "Bronze - Leaded Tin 215 (CDA 926)": 1.113,
  "Bronze - Leaded Tin 230 (CDA 923)": 1.12,
  "Bronze - Leaded Tin 245 (CDA 922)": 1.102,
  "Bronze - Manganese 421 (CDA 865)": 1.064,
  "Bronze - Manganese 423 (CDA 862)": 1.018,
  "Bronze - Manganese 424 (CDA 863)": 1.0,
  "Bronze - Tin 205 (CDA 907)": 1.12,
  "Bronze - Tin 210 (CDA 905)": 1.113,
  "Bronze - Tin 225 (CDA 903)": 1.124,
  "Bronze - Tobin": 0.912,
  "Cast Iron": 0.883,
  "Cellulose Acetate": 0.166,
  Columbium: 1.095,
  Copper: 1.144,
  "Copper - Beryllium C17000": 1.074,
  "Copper - Beryllium C17200": 1.067,
  "Copper - Beryllium C17300": 1.067,
  "Copper - Beryllium C17410": 1.124,
  "Copper - Beryllium C17500": 1.127,
  "Copper - Beryllium C17510": 1.127,
  "Copper - Silicone 500 (CDA 872 & 875)": 1.06,
  CPVC: 0.198,
  "Delrin (Acetal)": 0.18,
  "Duranickel - 301": 0.931,
  "Fiberglass Sheet": 0.237,
  Glass: 0.331,
  "Glass - Mica (Machining Grades)": 0.353,
  "Glass - Mica (Molding Grades)": 0.495,
  Gold: 2.466,
  Granite: 0.344,
  "Hastelloy - B-2": 1.174,
  "Hastelloy - C-276": 1.132,
  "Hastelloy - C-4": 1.1,
  "Hastelloy - G-3": 1.058,
  "Incoloy - 800": 1.014,
  "Incoloy - 802": 1.0,
  "Incoloy - 825": 1.039,
  "Inconel - 600": 1.074,
  "Inconel - 601": 1.028,
  "Inconel - 617": 1.067,
  "Inconel - 625": 1.077,
  "Inconel - 702": 0.931,
  "Inconel - 706": 0.915,
  "Inconel - 718": 1.046,
  "Inconel - 721": 0.947,
  "Inconel - 751": 0.934,
  "Inconel - 801": 0.903,
  "Inconel - X750": 1.053,
  "Iron Slag": 0.344,
  Lead: 1.448,
  Lexan: 0.153,
  "Machinable Glass Ceramic": 0.353,
  Magnesium: 0.229,
  "Malleable Iron - A220": 0.936,
  Manganese: 0.943,
  Molybendum: 1.303,
  Monel: 1.084,
  "Monel - 400": 1.0,
  "Monel - 401": 1.009,
  "Monel - 404": 1.013,
  "Monel - 502": 0.956,
  "Monel - K500": 0.959,
  "Monel - R405": 1.127,
  "Muntz Metal": 1.071,
  "Nickel - 200": 1.132,
  "Nickel - 201": 1.132,
  "Nickel - 205": 1.009,
  "Nickel - 211": 1.0,
  "Nickel - 220": 1.006,
  "Nickel - 230": 1.006,
  "Nickel - 270": 1.134,
  "Nickel - 400": 1.125,
  "Nickel - 600": 1.072,
  "Nickel - 625": 1.075,
  "Nickel - 718": 1.047,
  "Nickel - 800": 1.012,
  "Nickel - 800H": 1.012,
  "Nickel - 825": 1.037,
  "Nickel - 904L": 1.026,
  "Nickel - Copper CDA 962": 1.141,
  "Nickel - Copper CDA 964": 1.141,
  "Nickel - Silver 410 (CDA 973)": 1.134,
  "Nickel - Silver 412 (CDA 976)": 1.134,
  "Nickel - Silver 413 (CDA 978)": 1.13,
  "Nickel - X750": 1.051,
  "NI-SPAN-C - 902": 1.035,
  "Nylon 6 - 30% Glass": 0.1766,
  "Nylon 6 - Cast": 0.148,
  "Nylon 6/6 - Cast": 0.166,
  "Nylon 6/6 - Extruded": 0.145,
  "Nylon 60L - Cast": 0.148,
  "Permanickel - 300": 0.991,
  "PET - Unfilled": 0.173,
  Phenolics: 0.177,
  Platinum: 2.739,
  Plutonium: 2.526,
  Polycarbonate: 0.152,
  Polyetherimide: 0.163,
  "Polyethylene - HD": 0.124,
  "Polyethylene - UHMW": 0.12,
  Polymethylpentene: 0.106,
  "Polymid - Unfilled": 0.18,
  "Polyphenylene Sulfide": 0.166,
  Polypropylene: 0.117,
  Polysulfone: 0.159,
  Polyurethane: 0.134,
  "PVC (Polyvinyl Chloride)": 0.177,
  PVDF: 0.226,
  Silver: 1.339,
  "Stainless Steel - 300 Series": 1.01,
  "Stainless Steel - 400 Series": 1.0,
  Steel: 1.0,
  Tantalum: 2.12,
  "Teflon (PTFE)": 0.295,
  Tin: 0.932,
  Titanium: 0.575,
  Tungsten: 2.462,
  Uranium: 2.413,
  Zinc: 0.911,
  Zirconium: 0.812,
};

const FORM_DEFAULTS = {
  material: materialFactors["Default"],
  thickness: 0.0,
  width: 0.0,
  length: 0.0,
  diameter: 0.0,
  wallThickness: 0.0,
  pieces: 1,
  widthAcross: 0.0,
  leg1: 0.0,
  leg2: 0.0,
};

const TWELVE = 12;

const shapeCalculations = {
  square: (formData) => {
    const thickness = parseFloat(formData.thickness) || 0;
    const width = parseFloat(formData.width) || 0;
    const length = parseFloat(formData.length) || 0;
    if (thickness > 0) {
      const shapeFactor = 3.4032;
      return (width * thickness * shapeFactor * length) / TWELVE;
    }
    return 0;
  },
  square_tube: (formData) => {
    const thickness = parseFloat(formData.thickness) || 0;
    const wallThickness = parseFloat(formData.wallThickness) || 0;
    const width = parseFloat(formData.width) || 0;
    const length = parseFloat(formData.length) || 0;
    const wallThicknessDoubled = wallThickness * 2;

    console.log([
      thickness,
      wallThickness,
      width,
      length,
      wallThicknessDoubled,
    ]);

    if (thickness > 0) {
      const shapeFactor = 3.287;
      return (
        (thickness * width * shapeFactor -
          (thickness - wallThicknessDoubled) *
            (width - wallThicknessDoubled) *
            shapeFactor) *
        (length / TWELVE)
      );
    }
    return 0;
  },
  sheet: (formData) => {
    const thickness = parseFloat(formData.thickness) || 0;
    const width = parseFloat(formData.width) || 0;
    const length = parseFloat(formData.length) || 0;
    if (thickness > 0) {
      const shapeFactor = 3.4032;
      return (width * thickness * shapeFactor * length) / TWELVE;
    }
    return 0;
  },
  round: (formData) => {
    const diameter = parseFloat(formData.diameter) || 0;
    const length = parseFloat(formData.length) || 0;
    if (diameter > 0) {
      const shapeFactor = 2.6729;
      return (diameter * diameter * shapeFactor * length) / TWELVE;
    }
    return 0;
  },
  round_tube: (formData) => {
    const diameter = parseFloat(formData.diameter) || 0;
    const wallThickness = parseFloat(formData.wallThickness) || 0;
    const length = parseFloat(formData.length) || 0;
    const wallDifference = diameter - wallThickness;
    if (diameter > 0) {
      const shapeFactor = 10.68;
      return (shapeFactor * wallDifference * wallThickness * length) / TWELVE;
    }
    return 0;
  },
  hex: (formData) => {
    const width = parseFloat(formData.width) || 0;
    const length = parseFloat(formData.length) || 0;
    if (width > 0) {
      const shapeFactor = 2.9473;
      return (width * shapeFactor * length) / TWELVE;
    }
    return 0;
  },
  angle: (formData) => {
    const leg1Length = parseFloat(formData.leg1) || 0;
    const leg2Length = parseFloat(formData.leg2) || 0;
    const thickness = parseFloat(formData.thickness) || 0;
    const length = parseFloat(formData.length) || 0;
    if (leg1Length > 0) {
      const shapeFactor = 3.42;
      return (
        ((leg1Length * thickness * shapeFactor +
          (leg2Length - thickness) * thickness * shapeFactor) *
          length) /
        TWELVE
      );
    }
    return 0;
  },
};

const calculateTotalWeight = (shape, formData) => {
  const shapeWeight = shapeCalculations[shape]
    ? shapeCalculations[shape](formData)
    : 0;
  const materialFactor = parseFloat(formData.material) || 1;
  return (
    shapeWeight *
    materialFactor *
    (parseFloat(formData.pieces) || 1)
  ).toFixed(3);
};

const WeightEstimator = () => {
  const [shape, setShape] = useState("square");
  const [formData, setFormData] = useState(FORM_DEFAULTS);
  const [weight, setWeight] = useState(null);

  useEffect(() => {
    if (shape && Object.keys(formData).length > 0) {
      const calculatedWeight = calculateTotalWeight(shape, formData);
      setWeight(calculatedWeight);
    }
  }, [shape, formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleShapeChange = (e) => {
    setShape(e.target.value);
    //setFormData(FORM_DEFAULTS);
    setWeight(null);
  };

  return (
    <div className="rounded-xl shadow-lg p-12 bg-gray-100 border border-solid border-gray-400">
      <form className="grid grid-cols-1 md:grid-cols-12 gap-12">
        <div className="col-span-1 md:col-span-8 grid grid-cols-1 gap-12 bg-gray-100">
          <div>
            <label htmlFor="shape">Shape</label>
            <select id="shape" value={shape} onChange={handleShapeChange}>
              <option value="square">Square / Rectangle</option>
              <option value="square_tube">Square / Rectangular Tube</option>
              <option value="sheet">Sheet / Plate</option>
              <option value="round">Round</option>
              <option value="round_tube">Round Tube</option>
              <option value="hex">Hex</option>
              <option value="angle">Angle</option>
            </select>
          </div>

          <WeightEstimatorFormFields
            shape={shape}
            materialFactors={materialFactors}
            handleChange={handleChange}
            formData={formData}
          />
        </div>

        <div className="col-span-1 md:col-span-4 shadow-lg bg-black text-white rounded-lg p-12 flex flex-col justify-start gap-12">
          <div className="">
            <strong className="block mb-4">Total Weight:</strong>
            <span className="block text-5xl">{weight} lbs</span>
          </div>

          {weight > 0 && (
            <div className="">
              <a href="/request-a-quote" className="inline-block text-4xl rounded-lg bg-neutral-500 text-white py-6 px-8 hover:bg-neutral-400 hover:text-white">Request a Quote</a>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default WeightEstimator;
